import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElModule } from 'element-angular'; //引入ui库
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; //国际化
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'; //国际化
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BaseInterceptor } from './shared/utils/http.interceptor';
import { HomeComponent } from './components/common/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { DataViewComponent } from './components/common/data-view/data-view.component';
import { CommonModules } from './components/common/common.module';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NgxAmapModule } from 'ngx-amap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgxEchartsModule } from 'ngx-echarts';
// 对国际化进行http调用
export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
import { MtModule } from './mt.module';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
// You may be the next person to take over my code. Although he is rubbish, please treat him well
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SidebarComponent,
    DataViewComponent,
  ],
  imports: [
    BrowserModule,
    NzBadgeModule,
    NzTabsModule,
    AppRoutingModule,
    NzButtonModule,
    ElModule.forRoot(),
    NgxEchartsModule,
    NzNotificationModule,
    NzDropDownModule,
    NzIconModule,
    NzToolTipModule,
    HttpClientModule,
    NzSwitchModule,
    CommonModules,
    FormsModule,
    NzMenuModule,
    MtModule,
    IconsProviderModule,
    NgxAmapModule.forRoot({
      apiKey: '1faa72655ec83f5a52a854f0d8a8f8d8',
    }),
    //配置国际化
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateHttpLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: NzConfigService, useValue: { nzMaxStack: 3 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: zh_CN },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
