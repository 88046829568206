import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { ElModule } from 'element-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { LoginResetComponent } from './login-reset/login-reset.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DIRECTIVES } from 'src/app/shared/directives/index';
@NgModule({
  declarations: [LoginComponent, LoginResetComponent],
  imports: [
    ElModule,
    FormsModule,
    NzCheckboxModule,
    DIRECTIVES,
    ReactiveFormsModule,
    NzFormModule,
    CommonModule,
    TranslateModule,
    NzToolTipModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModules {}
