import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  //重写console对象下的方法,生产环境去掉日志
    window.console.log = function () { };
    window.console.info = function () { };
    window.console.warn = function () { };
    window.console.error = function () { }
    window.console.debug = function () { };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
