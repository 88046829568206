import { Injectable } from '@angular/core';
import { url, OpenUrl,urlv2,types,urlv3} from '../utils/config.url';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpEventType,
  HttpEvent,
} from '@angular/common/http';
import { catchError, map, tap, last } from 'rxjs/operators';
import { fn } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) { }

  /**
   * @params
   * Login
   */
  public Login(obj) {
    return this.http
      .post(
        `${OpenUrl}login?username=${obj.username}&password=${obj.password}`,
        null
      )
      .toPromise();
  }
  //退出登录
  public LoginOut() {
    return this.http.post(`${OpenUrl}logout`, null).toPromise();
  }
  /**
   * getTree
   */
  public getTree(type) {
    return this.http.get(`${url}/systemResource/tree?type=${type}`).toPromise();
  }

  /**
   * upLoadImg
   * 上传图片
   */
  public upLoadImg() {
    return `${OpenUrl}open/api/v1/common/upload/uploadImg`;
  }
  /**
   * sendInstruct
   * 发送指令
   */
  public sendInstruct(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/common/deviceCmd/sendCmd`, obj)
      .toPromise();
  }
  //发送业务指令
  public sendBusinessCmd(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/common/deviceCmd/business/sendCmd`, obj)
      .toPromise();
  }
  //通用-查看命令的执行结果
  public showCmdSendLog(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceCmd/showCmdSendLog?deviceId=${obj.deviceId}&cmdId=${obj.cmdId}`
      )
      .toPromise();
  }
  // 通用-查看业务指令的发送记录
  public showBusinessCmdSendLog(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceCmd/showBusinessCmdSendLog?deviceId=${obj.deviceId}&cmdId=${obj.cmdId}`
      )
      .toPromise();
  }
  // 通用-业务数据-历史-分页
  public getBusinessDataHistoryPage(pageLink, obj) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/deviceData/business/getBusinessDataHistoryPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  /**
   * upLoadApk
   * 上传Apk
   */
  public upLoadApk(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadApp`,
      file,
      {
        reportProgress: true,
      }
    );

    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }
  /**
   * upLoadMcu
   * 上传Mcu
   */
  public upLoadMcu(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadMcu`,
      file,
      {
        reportProgress: true,
      }
    );

    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }
  //上传MCu配置文件
  public upLoadMcuConfig(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadMcuConfig`,
      file,
      {
        reportProgress: true,
      }
    );

    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }
  //上传设备配置文件
  public uploadDeviceConfig(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadDeviceConfig`,
      file,
      {
        reportProgress: true,
      }
    );

    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }
  /**
   * uploadOta
   * 上传Ota
   */
  public uploadOta(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadOta`,
      file,
      {
        reportProgress: true,
      }
    );
    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }
  public uploadConfig(file, fn) {
    const req = new HttpRequest(
      'POST',
      `${OpenUrl}open/api/v1/common/upload/uploadConfig`,
      file,
      {
        reportProgress: true,
      }
    );
    return this.http
      .request(req)
      .pipe(map((event) => this.getEventMessage(event, fn)));
  }

  /**
   * uploadFile
   * 上传文件
   */
  public uploadFile() {
    return `${OpenUrl}open/api/v1/common/upload/uploadFile`;
  }

  /**
   * 获取省市区
   */
  public getListByParentIdAndLevel(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/geography/getListByParentIdAndLevel?parentId=${obj.parentId}&level=${obj.level}`
      )
      .toPromise();
  }
  /**
   * validJSON
   * 校验JSON
   */
  public validJSON(obj) {
    return this.http
      .post(`${OpenUrl}open/api/v1/common/json/validate`, obj)
      .toPromise();
  }
  /**
   * validateJSONAttr
   * 校验属性json
   */
  public validateJSONAttr(obj) {
    return this.http
      .post(`${OpenUrl}open/api/v1/common/json/validateJSONAttr`, obj)
      .toPromise();
  }
  /**
   * getVerifyCode
   * 获取短信验证码
   */
  public getVerifyCode(phone) {
    return this.http
      .get(
        `${OpenUrl}open/api/v1/common/phoneMsg/getVerifyCode?phoneNum=${phone}`
      )
      .toPromise();
  }
  //验证用户名存在
  public validUserName(username) {
    return this.http
      .get(
        `${OpenUrl}open/api/v1/common/validate/username?username=${username}`
      )
      .toPromise();
  }
  //验证手机号是否已存在
  public validPhone(phone) {
    return this.http
      .get(`${OpenUrl}open/api/v1/common/validate/phoneNum?phoneNum=${phone}`).toPromise()
      ;
  }
  //获取登录用户详情
  public getLoginUserDetail() {
    return this.http
      .get(`${OpenUrl}auth/api/v1/common/user/getLoginUserDetail`)
      .toPromise();
  }
  //修改个人信息
  modifyPersonalInfo(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/common/user/modifyPersonalInfo`, obj)
      .toPromise();
  }
  //修改密码
  modifyPassword(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/common/user/modifyPassword`, obj)
      .toPromise();
  }
  //通用-获取设备属性-分页
  public getAttrPage(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceData/getAttrPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  //通用-获取设备属性-分页
  public getCmdPage(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceData/getCmdPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  //通用-获取设备遥测-分页
  public getTsPage(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceData/getTsPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  // 通用-获取业务指令-分页
  public getBusinessCmdPage(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceData/business/getCmdPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  // 通用-设备事件
  getDeviceEventPage(pageLink, obj) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/deviceData/business/getDeviceEventPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  // 通用-获取业务数据-分页
  public getBusinessDataPage(obj) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/common/deviceData/business/getBusinessDataPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  //通用-获取设备某一遥测的连续值-分页
  public getTsKeyPage(pageLink, obj) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/deviceData/getTsKeyPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  //通用-获取设备某一遥测的连续值-不分页
  public getTsKeyList(obj) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/deviceData/getTsKeyList`, obj
      )
      .toPromise();
  }
  // 设备的轨迹地图
  public getTraceMap(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/common/deviceData/getTraceMap`, obj)
      .toPromise();
  }
  //通用-获取设备告警-分页
  public getAlarmPage(obj) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/deviceData/getAlarmPage?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}`,
        obj
      )
      .toPromise();
  }
  private getEventMessage(event: HttpEvent<any>, fn: Function) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0;
      // 正在上传
      case HttpEventType.UploadProgress:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return percentDone;
      // 上传完毕
      case HttpEventType.Response:
        // 回调
        fn(event.body);
        return 100;
      default:
        return 100;
    }
  }
  // 通用-审计日志详情-分页
  public getAuditList(pageLink, obj = {}) {
    return this.http
      .post(
        `${OpenUrl}auth/api/v1/common/auditLog/getPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  //下载日志文件
  public downloadLog(id) {
    return `${OpenUrl}open/api/v1/common/upload/downloadLog?recordId=${id}`;
  }
  //重置密码
  resetPassword(obj) {
    return this.http
      .post(`${OpenUrl}open/api/v1/common/resetPassword`, obj)
      .toPromise();
  }
}

@Injectable({
  providedIn: 'root',
})
//大屏数据展示
export class dataViewSevervice {
  constructor(private http: HttpClient) { }

  //获取区域-设备数量
  getRegionDeviceCountStatistic() {
    return this.http
      .get(
        `${urlv2}/databoard/getRegionDeviceCountStatistic`
      )
      .toPromise();
  }
  //产品-设备数量
  getProductDeviceCountStatistic() {
    return this.http
      .get(
        `${urlv2}/databoard/getProductDeviceCountStatistic`
      )
      .toPromise();
  }
  //租户-设备数量
  getTenantDeviceCountStatistic() {
    return this.http
      .get(
        `${urlv2}/databoard/getTenantDeviceCountStatistic`
      )
      .toPromise();
  }
  //平台消息量统计
  getSystemMsgCountStatistic(obj) {
    return this.http
      .get(
        `${urlv2}/databoard/getSystemMsgCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
  //活跃租户Top10
  getActiveTenantTop10() {
    return this.http
      .get(`${urlv2}/databoard/getActiveTenantTop10`)
      .toPromise();
  }
  //设备数量统计
  getDeviceCountStatistic(obj) {
    return this.http
      .get(
        `${urlv2}/databoard/getDeviceCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
  // 活跃设备Top10
  getActiveDeviceTop10() {
    return this.http
      .get(`${urlv2}/databoard/getActiveDeviceTop10`)
      .toPromise();
  }
  // 单一设备的消息量统计
  getMqttMsgCountStatistic(obj) {
    return this.http
      .get(
        `${urlv2}/databoard/getMqttMsgCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}&deviceId=${obj.deviceId}`
      )
      .toPromise();
  }
  //租户数量统计
  getTenantCountStatistic(obj) {
    let ZuKehu=types=="1"?"getTenantCountStatistic":types=="2"?"getCustomerCountStatistic":types=="3"?"getCustomerCountStatistic":""
    return this.http
      .get(
        `${urlv2}/databoard/${ZuKehu}?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
  //客户数量统计
  getCustomerCountStatistic(obj){

    return this.http
      .get(
        `${urlv2}/databoard/getCustomerCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
  //告警数量
  getAlarmCountStatistic(obj) {
    return this.http
      .get(
        `${urlv2}/databoard/getAlarmCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
  // 告警设备Top10
  getAlarmDeviceTop10() {
    return this.http
      .get(`${urlv2}/databoard/getAlarmDeviceTop10`)
      .toPromise();
  }
  getProductCountStatistic(obj) {
    return this.http
      .get(
        `${urlv2}/databoard/getProductCountStatistic?startTime=${obj.startTime}&endTime=${obj.endTime}`
      )
      .toPromise();
  }
}
