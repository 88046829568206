import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap, timeout } from 'rxjs/operators';
import { ElMessageService } from 'element-angular';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  constructor(
    private message: ElMessageService,
    private router: Router,
    private MqttService: MqttService
  ) { }
  intercept(req, next: HttpHandler): Observable<HttpEvent<any>> {
    //  req.headers('Accept')='application/json'
    // req.head
    // const baseurl = 'http://www.houluzhai.top:9500';
    // let newReq = req.clone({
    //   url: req.hadBaseurl ? `${req.url}` : `${baseurl}${req.url}`,
    // });
    // req.headers = { token: localStorage.getItem('HER_TOKEN') };
    const authReq = req.clone({
      headers: req.headers.set(
        'token',
        localStorage.getItem('HER_TOKEN') == null
          ? ''
          : localStorage.getItem('HER_TOKEN').slice(0, 36)
      ),
    });

    const defaultTimeout = 3000;

    return next.handle(authReq).pipe(
      // timeout(defaultTimeout),
      tap(
        (response) => {
          if (response instanceof HttpResponse) {
            this.message.setOptions({ zIndex: 99999 });
            if (response.status === 200 && response.body) {
              if (response.body.code == 500) {
                this.message.error(response.body.message);
              } else if (response.body.code != 200 && response.body.code) {

                this.message.error(response.body.message);
              }
            }
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status >= 500) {
              this.message.setOptions({ zIndex: 99999 });
              // this.message.error(`服务器内部错误,请联系管理员 `);
              this.router.navigate(['500']);
            } else if (error.status == 401) {
              this.message.error('登录超时，请重新登录');
              localStorage.removeItem('HER_TOKEN');
              localStorage.clear();
              this.MqttService.disconnect();
              this.router.navigate(['/login']);
            } else if (error.status == 400) {
              this.message.info('用户名或密码不正确，请重试');
            }
          }
        }
      )
    );
  }
}
 