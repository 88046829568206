function dateFormat(sj) {
  let now = new Date(sj);
  let year = now.getFullYear();
  let month: any = now.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let date: any = now.getDate();
  if (date < 10) {
    date = '0' + date;
  }
  let hour = now.getHours();
  let minute = now.getMinutes();
  let second = now.getSeconds();
  return year + '-' + month + '-' + date;
}

function dateFormattrim(sj) {
  let now = new Date(sj);
  let year = now.getFullYear();
  let months = now.getMonth() + 1;
  let month = months < 10 ? '0' + months : months;
  let dates = now.getDate();
  let date = dates < 10 ? '0' + dates : dates;
  let hour = now.getHours();
  let minute = now.getMinutes();
  let second = now.getSeconds();
  return year + '' + month + '' + date + '';
}

function dateFormatTime(sj: Date) {
  let now = new Date(sj);
  let year = now.getFullYear();
  let month: any = now.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let date = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
  let hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
  let minute =
    now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
  let second =
    now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
  return (
    year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
  );
}

function getCurrentDate(sj: Date) {
  let now = new Date(sj);
  let year = now.getFullYear();
  let month: any = now.getMonth() + 1;
  let days: any = now.getDay();
  month = month < 10 ? '0' + month : month;
  let date = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
  let hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
  let minute =
    now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
  let second =
    now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
  switch (days) {
    case 1:
      days = '   星期一';
      break;
    case 2:
      days = '   星期二';
      break;
    case 3:
      days = '   星期三';
      break;
    case 4:
      days = '   星期四';
      break;
    case 5:
      days = '   星期五';
      break;
    case 6:
      days = '   星期六';
      break;
    case 0:
      days = '   星期日';
      break;
  }
  return (
    year +
    '-' +
    month +
    '-' +
    date +
    ' ' +
    hour +
    ':' +
    minute +
    ':' +
    second +
    ' ' +
    days
  );
}

function dateFormatdate(sj) {
  return sj.slice(0, 4) + '-' + sj.slice(4, 6) + '-' + sj.slice(6, 8);
}
export {
  dateFormat,
  dateFormatTime,
  dateFormattrim,
  dateFormatdate,
  getCurrentDate,
};
