import { Component, OnInit, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/api/common.service';
import { ElMessageService } from 'element-angular';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { MyValidators } from 'src/app/shared/utils/Validators';
declare var SlidingVerificationCode: any;
declare var $: any;
@Component({
  selector: 'app-login-reset',
  templateUrl: './login-reset.component.html',
  styleUrls: ['./login-reset.component.scss'],
})
export class LoginResetComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private Router: Router,
    private CommonService: CommonService,
    private message: ElMessageService
  ) { }
  validateForm!: FormGroup;
  flag = false;
  downX: any;

  isFinish: boolean = false;
  timeSum: number = 60;
  ngOnInit(): void {
    const { required, mobile, maxLength, minLength } = MyValidators;
    this.validateForm = this.fb.group({
      phoneNum: [null, [required, mobile, maxLength(11)]],
      validateCode: [null, [required, maxLength(11)]],
      confirm: [null, [this.confirmValidator]],
      newPassword: [null, [required, maxLength(18), minLength(6)]],
    });
    this.inits()
  }
  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirm.updateValueAndValidity()
    );
  }
  public inits = function () {
    let that = this;
    console.log($('div:hidden').find('#captcha').get(0));
    console.log($('div').find('#captcha').get(0));
    var eles = ($('div:hidden').find('#captcha').length > 0) ? $('div:hidden').find('#captcha').get(0) : $('div').find('#captcha').get(0);
    SlidingVerificationCode.init(eles, function () {
      document.getElementById('msg').innerHTML = ''
      that.isFinish = true;
    }, function () {
      document.getElementById('msg').innerHTML = '验证失败'
      that.isFinish = false;
    })
  }


  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.newPassword.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  ngAfterViewInit(): void {
    console.log(
      this.el.nativeElement.querySelector('.con-con-slider').style.width
    );
  }
  mouseenter(e) { }
  mouseleave(e) {
    if (e) {
      e.preventDefault();
      if (this.isFinish == false) {
        this.el.nativeElement.querySelector('.slider').style.left = '0px';
      } else {
        this.el.nativeElement.querySelector('.slider').style.right = '0px';
      }
    }
  }
  //返回登录
  GoLogin() {
    this.Router.navigate(['/login/']);
  }
  mousedown(e) {
    this.downX = e.clientX;
    let that = this;
    if (that.el.nativeElement.querySelector('.slider').style.right == '0px') {
      that.el.nativeElement.querySelector('.slider').onmouseup = () => {
        that.el.nativeElement.querySelector('.slider').onmousemove = null;
        that.el.nativeElement.querySelector('.slider').onmouseup = null;
      };
      return;
    }
    e.preventDefault(); //阻止默认事件
    that.el.nativeElement.querySelector('.slider').onmousemove = (e) => {
      that.el.nativeElement.querySelector('.slider').style.left =
        e.clientX - that.downX + 'px';
      if (e.clientX - that.downX < 0) {
        that.el.nativeElement.querySelector('.slider').style.left = '0px';
      }
      if (e.clientX - that.downX == 348) {
        that.isFinish = true;
        that.el.nativeElement.querySelector('.slider').style.right = '0px';
        that.el.nativeElement.querySelector('.slider').onmouseup = () => {
          that.el.nativeElement.querySelector('.slider').onmousemove = null;
          that.el.nativeElement.querySelector('.slider').onmouseup = null;
        };
      } else if (e.clientX - that.downX > 360) {
        that.el.nativeElement.querySelector('.slider').style.left = '0px';
        that.isFinish = false;
      }
      e.preventDefault(); //阻止默认事件
    };
    that.el.nativeElement.querySelector('.slider').onmouseup = () => {
      that.el.nativeElement.querySelector('.slider').onmousemove = null;
      that.el.nativeElement.querySelector('.slider').onmouseup = null;
    };
  }
  getVeCode() {
    // 获取验证码
    this.getVerifyCode();
    let timeer = setInterval(() => {
      this.timeSum = this.timeSum - 1;
      if (this.timeSum == 0) {
        clearInterval(timeer);
        this.timeSum = 60;
      }
    }, 1000);
  }
  update() {
    if (this.validateForm.value.phoneNum) {
      this.CommonService.validPhone(this.validateForm.value.phoneNum).then(
        (res: any) => {
          if (!res.content) {
            this.message.info('该手机号未注册,请核对后再输入');
            this.validateForm.patchValue({
              phoneNum: '',
            });
          }
        }
      );
    }
  }
  getVerifyCode() {
    this.CommonService.getVerifyCode(this.validateForm.value.phoneNum).then(
      (res: any) => {
        if (res.code == 200) {
          this.message.success('验证码获取成功，请稍后');
        }
      }
    );
  }
  //提交
  submit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      let obj = {
        newPassword: this.validateForm.value.newPassword,
        phoneNum: this.validateForm.value.phoneNum,
        validateCode: this.validateForm.value.validateCode,
      };
      this.CommonService.resetPassword(obj).then((res: any) => {
        if (res.code == 200) {
          this.message.success('密码重置成功');
          this.Router.navigate(['/login/']);
        }
      });
    }
  }
}

// export type MyErrorsOptions = { 'zh-cn': string; en: string } & Record<
//   string,
//   NzSafeAny
// >;
// export type MyValidationErrors = Record<string, MyErrorsOptions>;

// export class MyValidators extends Validators {
//   static mobile(control: AbstractControl): MyValidationErrors | null {
//     const value = control.value;

//     if (isEmptyInputValue(value)) {
//       return null;
//     }

//     return isMobile(value)
//       ? null
//       : {
//         mobile: {
//           'zh-cn': `手机号码格式不正确`,
//           en: `Mobile phone number is not valid`,
//         },
//       };
//   }
// }

// function isEmptyInputValue(value: NzSafeAny): boolean {
//   return value == null || value.length === 0;
// }

// function isMobile(value: string): boolean {
//   return (
//     typeof value === 'string' &&
//     /^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)
//   );
// }
