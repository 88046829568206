import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/api/common.service';
import { CommunicateService } from 'src/app/communicate.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() private outer = new EventEmitter();
  constructor(
    private route: Router,
    private CommonService: CommonService,
    private cs: CommunicateService
  ) {}
  treeData: any[] = [];
  loginData:any={
    organizationName:'智慧物联网云平台',
    organizationLogo:'default'
  };
  isCollapsed = 'false';
  handleChange(event) {
    console.log(event)
    localStorage.removeItem(event.page)
    this.outer.emit(event);
  }
  ngOnChanges(changes: SimpleChanges): void {}
  private renderChartSubscription: Subscription = null;
  ngOnInit(): void {
    let loginDataStr = localStorage.getItem('organization');
    if(loginDataStr){
      this.loginData = JSON.parse(loginDataStr)
    }
    this.getTree();
    if (!this.renderChartSubscription) {
      this.renderChartSubscription = this.cs
        .getRenderSubject()
        .subscribe((res: any) => {
          this.isCollapsed = res;
        });
    }
  }
  openHandler(value): void {
    for (const key in this.treeData) {
      if (key != value) {
        this.treeData[key].checkd = false;
      }
    }
  }
  getTree(): void {
    let type = window.localStorage.getItem('HER_TOKEN').slice(36, 37);
    this.CommonService.getTree(type).then((res: any) => {
      this.treeData = res.content;
    });
  }

  toRoute() {
    this.route.navigate(['/flight-booking/flight-search']);
  }
}
