import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// 导入路由服务
import { Router } from '@angular/router';
import { ElMessageService } from 'element-angular';
import { RouteStrategyService } from "src/app/route-strategy.service";
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private message: ElMessageService) { }
  canActivate(): boolean {
    const token = localStorage.getItem('HER_TOKEN');
    if (!!token) {
      return true;
    } else {
      // 找不到token，跳转到登录页
      this.router.navigate(['/login']);
      this.message.error('未登录，请您先登录');
      RouteStrategyService.clear()
      return false;
    }
  }
}
