import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

export type MyErrorsOptions = { 'zh-cn': string; en: string } & Record<
  string,
  NzSafeAny
>;
export type MyValidationErrors = Record<string, MyErrorsOptions>;

export class MyValidators extends Validators {
  constructor(
    private translate:TranslateService
  ){
    super();
  }
  static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.minLength(minLength)(control) === null) {
        return null;
      }
      return {
        minlength: {
          'zh-cn': `最小长度为 ${minLength}`,
          en: `MinLength is ${minLength}`,
        },
      };
    };
  }

  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.maxLength(maxLength)(control) === null) {
        return null;
      }
      return {
        maxlength: {
          'zh-cn': `最大长度为 ${maxLength}`,
          en: `MaxLength is ${maxLength}`,
        },
      };
    };
  }
  static pattern(pattern: string): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      console.log(Validators.pattern(pattern)(control));

      if (Validators.pattern(pattern)(control) === null) {
        return null;
      }
      return {
        pattern: {
          'zh-cn': `输入内容有误`,
          en: `输入内容有误`,
        },
      };
    };
  }

  static email(control: AbstractControl): MyValidationErrors | null {
    if (Validators.email(control) === null) {
      return null;
    }
    return {
      email: {
        'zh-cn': `邮箱格式不正确`,
        en: ` `,
      },
    };
  }

  static mobile(control: AbstractControl): MyValidationErrors | null {
    const value = control.value;

    if (isEmptyInputValue(value)) {
      return null;
    }

    return isMobile(value)
      ? null
      : {
          mobile: {
            'zh-cn': `手机号码格式不正确`,
            en: `Mobile phone number is not valid`,
          },
        };
  }
}

function isEmptyInputValue(value: NzSafeAny): boolean {
  return value == null || value.length === 0;
}

function isMobile(value: string): boolean {
  return (
    typeof value === 'string' &&
    /^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)
  );
}
