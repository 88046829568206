import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMqttMessage, MqttModule, MqttService } from 'ngx-mqtt';
let data = JSON.parse(localStorage.getItem('loginData'));
// 192.168.2.87
//var hostName = '//39.98.61.38';
// var hostName = '//192.168.2.87';
 var hostName = '//online.starsaiot.com';
export const MQTT_SERVICE_OPTIONS = {
  hostname: hostName,
  port: 8083,
  clientId:
    'web_' +
    (data?.organizationType == 1 ? 'admin' : data?.organizationId) +
    '_' +
    data?.userId +
    '_' +
    new Date().getTime(),
  path: '/mqtt',
  username: 'hercules',
  password: 'hercules',
};

@NgModule({
  declarations: [],
  imports: [CommonModule, MqttModule.forRoot(MQTT_SERVICE_OPTIONS)],
})
export class MtModule { }
