import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../shared/api/common.service';
import { Router } from '@angular/router';
import { CommunicateService } from 'src/app/communicate.service';
import { NewsService } from '../../../shared/api/message.service';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { RouteStrategyService } from "src/app/route-strategy.service";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() private outer = new EventEmitter();
  constructor(
    private TranslateService: TranslateService,
    private CommonService: CommonService,
    private Router: Router,
    private cs: CommunicateService,
    private NewsService: NewsService,
    private MqttService: MqttService,
  ) { }
  isCollapsed = 'false';
  userData: any;
  newsData: any;
  loading = false;
  readStatus: boolean = false;
  switchValue: boolean = true;
  loginData:any={
    organizationName:'智慧物联网云平台',
    organizationLogo:'default'
  };
  checkCollapsed() {
    if (this.isCollapsed == 'true') {
      this.isCollapsed = 'false';
      this.cs.emitRenderInfo('false');
    } else {
      this.isCollapsed = 'true';
      this.cs.emitRenderInfo('true');
    }
    this.outer.emit(this.isCollapsed);
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.loginData);
    this.getTenantNews();
    let loginDataStr = localStorage.getItem('organization');
    if(loginDataStr){
      this.loginData = JSON.parse(loginDataStr)
    }
    
  }
  //获取消息
  getTenantNews() {
    this.NewsService.getTenantNews({ pageNo: 1, pageSize: 3 }).then(
      (res: any) => {
        res.content.data.every((item) => {
          if (item.readStatus == 0) {
            this.readStatus = true;
            return false;
          } else {
            this.readStatus = false;
            return true;
          }
        });
        this.newsData = res.content.data;
      }
    );
  }
  clickSwitch() {
    if (!this.loading) {
      this.loading = true;
      setTimeout(() => {
        this.switchValue = !this.switchValue;
        this.loading = false;
      }, 300);
    }
    this.outer.emit({ switchValue: this.switchValue });
  }
  //跳转到消息详情
  goNewsDetail(id: string) {
    this.Router.navigate(['/person/news-detail/'], { queryParams: { id: id } });
  }
  // 选择中文
  changeChinese() {
    this.TranslateService.setDefaultLang('zh');
    this.TranslateService.use('zh');
    localStorage.setItem('currentLanguage', 'zh');
  }
  //选择英文
  changeEnglish() {
    this.TranslateService.setDefaultLang('en');
    this.TranslateService.use('en');
    localStorage.setItem('currentLanguage', 'en');
  }
  //状态改变
  change($event) {
    if ($event) {
      this.getTenantNews();
    }
  }
  //退出登录
  LoginOut() {
    this.CommonService.LoginOut().then((res: any) => {
      localStorage.removeItem('HER_TOKEN');
      this.Router.navigate(['/login']);
      let organization = localStorage.getItem('organization')
      localStorage.clear();
      localStorage.setItem('organization',organization)
      RouteStrategyService.clear()
    });
  }
  //租户消息全部
  goNewsTenant() {
    this.Router.navigate(['/person/news-tenant/']);
  }
  //账户设置
  goUserSet() {
    this.Router.navigate(['/person/personal/']);
  }
  //清空消息
  ClearNews() {
    let obj = {
      isAll: true,
      batchIds: [],
      organizationId: this.userData.organizationId,
    };
    this.NewsService.newsRemove(obj).then((res: any) => {
      if (res.code === 200) {
        this.getTenantNews();
      }
    });
  }
  dataView() {
    //数据展示
    this.Router.navigate(['/data-view/']);
  }
}
