import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/api/common.service';
import { base64_encode } from "src/app/shared/utils/base64";
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormControl,
  Validators,
} from '@angular/forms';
import { from } from 'rxjs';
import { getType } from '../../../shared/utils/config.url';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  userInfo: any = {
    user: '',
    pass: '',
  };
  loginData:any={
    organizationName:'智慧物联网云平台',
    organizationLogo:'default'
  };
  checked: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private CommonService: CommonService,
    private _mqttService: MqttService
  ) { }
  LoginReset(): void {
    this.router.navigate(['login-reset']);
  }
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (!this.validateForm.invalid) {
      let obj: any = {
        username: this.validateForm.value.username,
        password: base64_encode(this.validateForm.value.password),
      };
      this.CommonService.Login(obj).then((res: any) => {
        console.log(res,"返回的token和权限列表")
        localStorage.setItem(
          `HER_TOKEN`,
          res.content.token + res.content.organizationType
        );
        getType(res.content.organizationType);
        localStorage.setItem('loginData', JSON.stringify(res.content));
        localStorage.setItem('organization', JSON.stringify(res.content));
        // console.log(localStorage.getItem('organization'))
        localStorage.setItem('isFirstLogin', 'true');
        this.router.navigate(['']);
        if (this.checked) {
          this.setCookie(
            this.validateForm.value.username,
            this.validateForm.value.password,
            3
          );
        } else {
          this.clearCookie();
        }
      });
    }
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    let loginDataStr = localStorage.getItem('organization');
    if(loginDataStr){
      this.loginData = JSON.parse(loginDataStr)
    }
    this.getCookie();
  }
  //设置cookie
  setCookie(c_name, c_pwd, exdays) {
    var exdate = new Date(); //获取时间
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
    //字符串拼接cookie
    window.document.cookie =
      'userName' + '=' + c_name + ';path=/;expires=' + exdate.toUTCString();
    window.document.cookie =
      'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toUTCString();
  }
  getCookie() {
    if (document.cookie.length > 0) {
      var arr = document.cookie.split('; ');
      for (var i = 0; i < arr.length; i++) {
        var arr2 = arr[i].split('='); //再次切割
        //判断查找相对应的值
        if (arr2[0] == 'userName') {
          this.userInfo.user = arr2[1]; //保存到保存数据的地方
        } else if (arr2[0] == 'userPwd') {
          this.userInfo.pass = arr2[1];
        }
      }
      this.checked = true;
      this.validateForm.setValue({
        username: this.userInfo?.user,
        password: this.userInfo?.pass,
      });
    }
  }
  //清除cookie
  clearCookie() {
    this.setCookie('', '', -1); //修改2值都为空，天数为负1天就好了
  }
}
