import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
export class RouteMsg {
  url: string = '';
  type: string = '';
  constructor(type: string, url: string) {
    this.type = type;
    this.url = url;
  }
}
export class RouteStrategyService implements RouteReuseStrategy {

  public static handlers: { [key: string]: DetachedRouteHandle } = {};
  public static routeText$ = new Subject<RouteMsg>();
  // 订阅路由
  public static getRouteText(): Observable<RouteMsg> {
    return RouteStrategyService.routeText$.asObservable();
  }
  // 删除某个路由缓存
  public static deleteRouteSnapshot(path: string): void {
    console.log(path);
    const name = path.replace(/\//g, '_');
    if (RouteStrategyService.handlers[name]) {
      delete RouteStrategyService.handlers[name];
    }
  }
  // 清除全部路由缓存
  public static clear(): void {
    console.log(RouteStrategyService.handlers);
    RouteStrategyService.handlers = {}
    // for (let key in RouteStrategyService.handlers) {
    //   delete RouteStrategyService.handlers[key];
    // }
    console.log(RouteStrategyService.handlers);

  }
  //  匹配路由
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    console.debug('shouldDetach======>', route);
    if (!route.data.keep) {
      return false;
    }
    RouteStrategyService.routeText$.next(new RouteMsg('detach', route['_routerState'].url));
    return true;
  }
  // 保存路由
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    console.debug('store======>', route, handle);
    RouteStrategyService.handlers[this.getRouteUrl(route)] = handle;
  }
  // 判断路由是否应该被缓存
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    console.debug('shouldAttach======>', route);
    if (route.routeConfig['path'] == 'login') {
      RouteStrategyService.handlers = {}
    }
    return !route.routeConfig.children && !route.routeConfig.loadChildren &&
      !!RouteStrategyService.handlers[this.getRouteUrl(route)];
  }
  //检索
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    console.debug('retrieve======>', route);
    if (route.routeConfig.children || route.routeConfig.loadChildren || !RouteStrategyService.handlers[this.getRouteUrl(route)]) {
      return null;
    }
    RouteStrategyService.routeText$.next(new RouteMsg('attach', route['_routerState'].url));
    return RouteStrategyService.handlers[this.getRouteUrl(route)];
  }
  // 判断是否是重复路由
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    console.debug('shouldReuseRoute======>');
    return future.routeConfig === curr.routeConfig && JSON.stringify(future.params) === JSON.stringify(curr.params);
  }
  //获取当前路由url
  getRouteUrl(route: ActivatedRouteSnapshot) {
    const path = route['_routerState'].url.replace(/\//g, '_');
    return path;
  }
}
