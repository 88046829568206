import {
  Component,
  OnInit,
  NgZone,
  TemplateRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  trigger,
  style,
  animate,
  transition,
  query,
  group,
  keyframes,
} from '@angular/animations';
import { CommonService } from '../../../shared/api/common.service';
import { getType } from '../../../shared/utils/config.url';
import { Subscription } from 'rxjs';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { NzIconService } from 'ng-zorro-antd/icon';
import { ElMessageService } from 'element-angular';
import {
  edit,
  remove,
  detail,
  detailList,
  publish,
  downLoad,
  unPublish,
  onLine,
  fail,
  hisLog,
  unLine,
  linkImg,
  copy,
  sendCmd,
  unEdit,
  goBack,
  assign,
  unAssign,
  waitUpload,
  failUpload,
  cancelUpload,
  successUpload,
  viewAdd,
  validOk,
  validNo,
  filter,
  marker,
  reset
} from '../../../shared/utils/icon';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

  animations: [
    trigger('routeAnimations', [
      transition('HomePage <=> LoginPage', [
        group([
          query(':enter', [
            animate(
              '2000ms ease-in-out',
              keyframes([
                style({ transform: 'scale(0) translateX(100%)', offset: 0 }),
                style({ transform: 'scale(0.5) translateX(25%)', offset: 0.3 }),
                style({ transform: 'scale(1) translateX(0%)', offset: 1 }),
              ])
            ),
          ]),
          query(':leave', [
            animate(
              '2000ms ease-in-out',
              keyframes([
                style({ transform: 'scale(1)', offset: 0 }),
                style({
                  transform: 'scale(0.5) translateX(-25%) rotate(0)',
                  offset: 0.35,
                }),
                style({
                  opacity: 0,
                  transform: 'translateX(-50%) rotate(-180deg) scale(6)',
                  offset: 1,
                }),
              ])
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  isCollapsed = 'false';
  pageRoute: string;
  @ViewChild('tpl')
  tpl: TemplateRef<any>;
  private Subscription1: Subscription;
  private Subscription2: Subscription;
  index: number;
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
  isShow: string;
  FirstLogin: any;
  message: any;
  type: number = 0;
  constructor(
    private route: Router,
    private CommonService: CommonService,
    private _ngzone: NgZone,
    private _mqttService: MqttService,
    private el: ElementRef,
    public notification: NzNotificationService,
    private NzIconService: NzIconService,
    private messages: ElMessageService
  ) {
    //Unsealed, time sealed, label
    this.NzIconService.addIconLiteral('Btn:edit', edit);
    this.NzIconService.addIconLiteral('Btn:delete', remove);
    this.NzIconService.addIconLiteral('Btn:reset', reset);
    this.NzIconService.addIconLiteral('Btn:detail', detail);
    this.NzIconService.addIconLiteral('Btn:detailList', detailList);
    this.NzIconService.addIconLiteral('Btn:publish', publish);
    this.NzIconService.addIconLiteral('Btn:downLoad', downLoad);
    this.NzIconService.addIconLiteral('Btn:unPublish', unPublish);
    this.NzIconService.addIconLiteral('Btn:onLine', onLine);
    this.NzIconService.addIconLiteral('Btn:fail', fail);
    this.NzIconService.addIconLiteral('Btn:viewAdd', viewAdd);
    this.NzIconService.addIconLiteral('Btn:unLine', unLine);
    this.NzIconService.addIconLiteral('Btn:unEdit', unEdit);
    this.NzIconService.addIconLiteral('Btn:sendCmd', sendCmd);
    this.NzIconService.addIconLiteral('Btn:linkImg', linkImg);
    this.NzIconService.addIconLiteral('Btn:hisLog', hisLog);
    this.NzIconService.addIconLiteral('Btn:goBack', goBack);
    this.NzIconService.addIconLiteral('Btn:assign', assign);
    this.NzIconService.addIconLiteral('Btn:unAssign', unAssign);
    this.NzIconService.addIconLiteral('Btn:waitUpload', waitUpload);
    this.NzIconService.addIconLiteral('Btn:failUpload', failUpload);
    this.NzIconService.addIconLiteral('Btn:cancelUpload', cancelUpload);
    this.NzIconService.addIconLiteral('Btn:successUpload', successUpload);
    this.NzIconService.addIconLiteral('Btn:copy', copy);
    this.NzIconService.addIconLiteral('Btn:validOk', validOk);
    this.NzIconService.addIconLiteral('Btn:validNo', validNo);
    this.NzIconService.addIconLiteral('Btn:filter', filter);
    this.NzIconService.addIconLiteral('Btn:marker', marker)
    this._mqttService.connect();
    this.Subscription1 = this._mqttService
      .observe(
        `/web/${JSON.parse(localStorage.getItem('loginData')).organizationType == 1
          ? 'admin'
          : JSON.parse(localStorage.getItem('loginData')).organizationId
        }/alarm/inform`
      )
      .subscribe((message: IMqttMessage) => {
        if (message) {
          this.message = JSON.parse(
            JSON.parse(message.payload.toString()).msgBody
          );
          console.log(JSON.parse(message.payload.toString()));

          console.log(this.message);
          this.createBasicNotification(this.tpl);
          this.type = 1;
        }
      });
    this.Subscription2 = this._mqttService
      .observe(
        `/web/${JSON.parse(localStorage.getItem('loginData')).organizationType == 1
          ? 'admin'
          : JSON.parse(localStorage.getItem('loginData')).organizationId
        }/sitenews/inform`
      )
      .subscribe((message: IMqttMessage) => {
        if (message) {
          this.type = 2;
          this.message = JSON.parse(
            JSON.parse(message.payload.toString()).msgBody
          );
          this.createBasicNotification(this.tpl);
        }
      });
  }
  isFirstLogin: any;
  loginData: any;
  treeData: Array<any> = [];
  Goback() {
    this.isFirstLogin = 'false';
    localStorage.setItem('isFirstLogin', 'false');
  }
  ngOnInit(): void {
    getType(window.localStorage.getItem('HER_TOKEN').slice(36, 37));
    this.isFirstLogin = 'false';
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.FirstLogin = localStorage.getItem('isFirstLogin');
    if (this.loginData.phone == '' && this.FirstLogin == 'true') {
      this.isFirstLogin = 'true';
    }
  }
  ngAfterViewInit(): void { }
  handlerChild(event) {
    this.treeData.push(event);
    this.treeData = [...new Set(this.treeData)];
    let datas = [];
    this.treeData.forEach((val) => {
      datas.push(val.id);
    });
    this.index = datas.indexOf(event.id);
  }
  toShow(id): void {
    this.isShow = id;
  }
  toHide(id): void {
    this.isShow = '0';
  }
  closeTab() { }
  removeRoutes(id, e?: MouseEvent) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.treeData.forEach((value, index) => {
      if (value.id == id) {
        this.treeData.splice(index, 1);
      }
    });
    if (this.treeData.length < 1) {
      this.route.navigate(['']);
    } else {
      this.route.navigate([this.treeData[this.treeData.length - 1].page]);
    }
    this.index = this.treeData.length - 1;
  }

  getTree(): void {
    let type = window.localStorage.getItem('HER_TOKEN').slice(36, 37);
    this.CommonService.getTree(type).then((res: any) => {
      this.treeData = res.content;
    });
  }
  toRoutes(e) {
    this.route.navigate([e]);
  }
  handlerHeaderChild(val) {
    this.isCollapsed = val;
    console.log(val.switchValue);
    if (val.switchValue != undefined) {

      if (val?.switchValue) {
        this.Subscription1.unsubscribe();
        this.messages.success('告警关闭')
      } else {
        this.messages.success('告警打开')
        this.Subscription1 = this._mqttService
          .observe(
            `/web/${JSON.parse(localStorage.getItem('loginData')).organizationType == 1
              ? 'admin'
              : JSON.parse(localStorage.getItem('loginData')).organizationId
            }/alarm/inform`
          )
          .subscribe((message: IMqttMessage) => {
            console.log(message);

            if (message) {
              this.message = JSON.parse(
                JSON.parse(message.payload.toString()).msgBody
              );
              this.createBasicNotification(this.tpl);
              this.type = 1;
            }
          });
      }
    }

  }
  userDetail() {
    //租户详情
    this.route.navigate(['/person/personal/']);
  }
  createBasicNotification(template?: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'bottomRight',
      nzDuration: 2800,
      nzCloseIcon: 'close',
      nzStyle: {
        width: '360px',
        height: '190px',
      },
    });
  }
  ngOnDestroy(): void {
    this.Subscription1.unsubscribe();
    this.Subscription2.unsubscribe();
    this.NzIconService.clear();
  }
  goNewsDetail(id) {
    this.route.navigate(['/person/news-detail/'], { queryParams: { id: id } });
  }
  goAlarmDetail(id) {
    this.route.navigate(['/alarm/alarm-detail'], { queryParams: { id: id } });
  }
}
