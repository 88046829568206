// export const OpenUrl = 'http://192.168.2.196:9600/';
var types: any;
var url: any;
var urlv2:any;
var urlv3:any;
// var OpenUrl = 'http://192.168.2.87:9600/hercules/';

//var OpenUrl = 'http://iot.iperfumetech.com:9600/hercules/';

 var OpenUrl = 'http://online.starsaiot.com:9600/hercules/';

// var OpenUrl = 'http://192.168.2.196:9600/hercules/';

 
function getType(type) {
  types = type || window.localStorage.getItem('HER_TOKEN').slice(36, 37);
  urlv2 =types == 1 ? `${OpenUrl}auth/api/v2/admin` :types == 2? `${OpenUrl}auth/api/v2/tenant`:`${OpenUrl}auth/api/v2/customer`;
  url =types == 1 ? `${OpenUrl}auth/api/v1/admin` :types == 2? `${OpenUrl}auth/api/v1/tenant`:`${OpenUrl}auth/api/v1/tenant`;
  urlv3 =types == 1 ? `${OpenUrl}auth/api/v1/admin` :types == 2? `${OpenUrl}auth/api/v1/tenant`:`${OpenUrl}auth/api/v2/customer`;
}
export { getType, url, OpenUrl, types, urlv2,urlv3};
