import { Component, OnInit } from '@angular/core';
import { getCurrentDate } from 'src/app/shared/utils/DateUitls';
import { dataViewSevervice } from 'src/app/shared/api/common.service';
import { getType } from '../../../../app/shared/utils/config.url';
import { types} from '../../../shared/utils/config.url'
declare var echarts: any;
import * as $ from 'jquery';
import { from } from 'rxjs';
@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss'],
})
export class DataViewComponent implements OnInit {
  deviceData: any;
  deviceIndex: any;
  deviceCount: any;
  tenantCount: any;
  customerCount:any;
  messages:any
  messagesFlag:false
  newNum:any=1;
  constructor(private dataViewSevervice: dataViewSevervice) {}
  timmer: any;
  DateTime: any;
  types=window.localStorage.getItem('HER_TOKEN').slice(36, 37);
  startTime = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  endTime = new Date().getTime();
  tenantVisible: boolean = true;
  alarmVisible: boolean = true;
  countVisible: boolean = true;
  deviceVisible: boolean = true;
  costype:boolean=true;
  ngOnInit(): void {
    getType(window.localStorage.getItem('HER_TOKEN').slice(36, 37));
    this.createTime();
    this.getDeviceCount();
    this.getAlarmDeviceTop10();
    this.getDeviceTop10();
    this.getSystemMsgCountStatistic();
    this.createMap();
    this.getProductDeviceCount();
    this.getTenantDeviceCount();
    this.getActiveTenantTop10();
    this.getcostype()
  }
  getcostype(){
    if(this.types=="1"){
      this.costype=true
    }else{
      this.costype=false
    }
  }
  createTime() {
    this.timmer = setInterval(() => {
      this.DateTime = getCurrentDate(new Date());
    }, 1000);
  }
  ngOnDestroy(): void {
    clearInterval(this.timmer);
  }
  //构建地图
  createMap() {
    this.dataViewSevervice.getRegionDeviceCountStatistic().then((res: any) => {
      let dataBox = [];
      this.deviceCount = res.content.deviceCount;
      this.customerCount=res.content.customerCount
      this.tenantCount = res.content.tenantCount;
      res.content.regionDeviceList.forEach((val) => {
        // if (val.provinceName.indexOf('省') != -1) {
        //   dataBox.push({
        //     name: val.provinceName.slice(0, 2),
        //     value: (val.center + ',' + val.count).split(','),
        //   });
        // } else {
        //   dataBox.push({
        //     name: val.provinceName,
        //     value: (val.center + ',' + val.count).split(','),
        //   });
        // }
        if(val.provinceName.indexOf('北京')!=-1){
          dataBox.push({
            name: "北京",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('天津')!=-1){
          dataBox.push({
            name: "天津",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('上海')!=-1){
          dataBox.push({
            name: "上海",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('重庆')!=-1){
          dataBox.push({
            name: "重庆",
            value: (val.center + ',' + val.count).split(','),
          });
        }
        else if(val.provinceName.indexOf('黑龙江')!=-1){
          dataBox.push({
            name: "黑龙江",
            value: (val.center + ',' + val.count).split(','),
          });
        }
        else if(val.provinceName.indexOf('海南')!=-1){
          dataBox.push({
            name: "海南",
            value: (val.center + ',' + val.count).split(','),
          });
        }
        else if(val.provinceName.indexOf('台湾')!=-1){
          dataBox.push({
            name: "台湾",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('内蒙古')!=-1){
          dataBox.push({
            name: "内蒙古",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('广西')!=-1){
          dataBox.push({
            name: "广西",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('西藏')!=-1){
          dataBox.push({
            name: "西藏",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('宁夏')!=-1){
          dataBox.push({
            name: "宁夏",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('新疆')!=-1){
          dataBox.push({
            name: "新疆",
            value: (val.center + ',' + val.count).split(','),
          });
        }
        else if(val.provinceName.indexOf('香港')!=-1){
          dataBox.push({
            name: "香港",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('澳门')!=-1){
          dataBox.push({
            name: "澳门",
            value: (val.center + ',' + val.count).split(','),
          });
        }else if(val.provinceName.indexOf('南海诸岛')!=-1){
          dataBox.push({
            name: "南海诸岛",
            value: (val.center + ',' + val.count).split(','),
          });
        }else{
          dataBox.push({
                name: val.provinceName.slice(0, 2),
                value: (val.center + ',' + val.count).split(','),
          });
        }
      });
      let myecharts = echarts.init(document.getElementById('chinaMap'));
      let option = {
        geo: {
          map: 'china',
          aspectScale: 0.85,
          layoutCenter: ['50%', '50%'], //地图位置
          layoutSize: '125%',
          itemStyle: {
            normal: {
              shadowColor: '#1A2EAE',
              shadowOffsetX: -2,
              shadowOffsetY: 15,
              opacity: 1,
            },
            emphasis: {
              areaColor: '#276fce',
            },
          },
          regions: [
            {
              name: '南海诸岛',
              itemStyle: {
                areaColor: 'rgba(0, 10, 52, 1)',
                borderColor: 'rgba(0, 10, 52, 1)',
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                    color: '#009cc9',
                  },
                },
              },
              label: {
                show: false,
                color: '#FFFFFF',
                fontSize: 12,
              },
            },
          ],
        },
        tooltip: {
          triggerOn: 'mousemove',
          formatter: function (params) {
            var a = '<span>省份：' + params.name + '</span><br/>';
            var b =
              '<span>设备量：</span><span style="color:' +
              'rgba(23, 240, 204)' +
              '">' +
              (params.data?.value[2] ? params?.data.value[2] : 0) +
              '</span>';
            return [a, b].join('  ');
          },
          backgroundColor: 'rgba(29, 38, 71)',
          // 额外附加的阴影
          extraCssText: 'box-shadow:0 0 4px rgba(11, 19, 43,0.8);',
        },
        series: [
          {
            type: 'map',
            mapType: 'china',
            aspectScale: 0.85,
            data: dataBox,
            layoutCenter: ['50%', '50%'], //地图位置
            layoutSize: '125%',
            zoom: 1, //当前视角的缩放比例
            scaleLimit: {
              min: 1,
              max: 2,
            },
            itemStyle: {
              normal: {
                areaColor: '#334eff',
                borderColor: '#00FFFF',
                borderWidth: 1,
              },
              emphasis: {
                areaColor: '#00FFFF',
                label: {
                  color: '#000',
                },
              },
            },
          },
          {
            name: '散点',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'fill',
            },
            itemStyle: {
              normal: {
                color: '#F4E925',
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            symbol: 'circle',
            data: dataBox,
            // symbolSize: 20,
            symbolSize: function (val) {
              if (0 < val[2] && val[2] <= 10) {
                return 8;
              } else if (10 < val[2] && val[2] <= 50) {
                return 12;
              } else if (50 < val[2] && val[2] <= 100) {
                return 14;
              } else if (100 < val[2] && val[2] <= 500) {
                return 18;
              } else if (500 < val[2] && val[2] <= 1000) {
                return 22;
              } else {
                return 26;
              }
            },
            zIndex: 9999,
            showEffectOn: 'render', //加载完毕显示特效
          },
        ],
      };
      myecharts.setOption(option);
    });
  }
  //获取产品设备数量
  getProductDeviceCount() {
    this.dataViewSevervice.getProductDeviceCountStatistic().then((res: any) => {
      let dataBox = [];
      res.content.forEach((val) => {
        dataBox.push({ name: val.productName, value: val.count });
      });
      let myecharts = echarts.init(
        document.getElementById('productDeviceCharts')
      );
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> {c}台 ({d}%)',
        },
        color: [
          '#9447ff',
          '#ff4089',
          '#ffaf40',
          '#409fff',
          '#56f545',
          '#ff571a',
          '#47ffed',
          '#9354eb',
        ],
        series: [
          {
            type: 'pie',
            radius: ['50%', '62%'],
            avoidLabelOverlap: true,
            label: {
              normal: {
                formatter(v) {
                  let text = v.name;
                  let value_format = v.value + '台';
                  let percent_format = '{' + Math.round(v.percent) + '%}';
                  if (text.length <= 6) {
                    return `${text}\n${value_format}${percent_format}`;
                  } else if (text.length > 6 && text.length <= 12) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6
                    )}\n${value_format}${percent_format}`);
                  } else if (text.length > 12 && text.length <= 18) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12)}\n${value_format}${percent_format}`);
                  } else if (text.length > 18 && text.length <= 24) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18
                    )}\n${value_format}${percent_format}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18,
                      24
                    )}\n${text.slice(24)}\n${value_format}${percent_format}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18,
                      24
                    )}\n${text.slice(24, 30)}\n${text.slice(
                      30
                    )}\n${value_format}${percent_format}`);
                  }
                },
                color: '#fff',
              },
              borderColor: '#aaa',
              borderRadius: 15,
              position: 'top',
            },
            labelLine: {
              show: true,
            },
            data: dataBox,
          },
        ],
      };
      myecharts.setOption(option);
    });
  }
  //获取租户设备数量
  getTenantDeviceCount() {
    this.dataViewSevervice.getTenantDeviceCountStatistic().then((res: any) => {
      let dataBox = [];
      if(this.types=="1"){
        res.content.forEach((val) => {
          dataBox.push({ name: val.tenantName, value: val.count });
        });
      }else if(this.types=="2"||this.types=="3"){
        res.content.forEach((val) => {
          dataBox.push({ name: val.customerName, value: val.count });
        });
      }
      let myecharts = echarts.init(
        document.getElementById('tenantDeviceCharts')
      );
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> {c}台 ({d}%)',
        },
        color: [
          '#9447ff',
          '#ff4089',
          '#ffaf40',
          '#409fff',
          '#56f545',
          '#ff571a',
          '#47ffed',
          '#9354eb',
        ],
        series: [
          {
            type: 'pie',
            radius: ['50%', '62%'],
            avoidLabelOverlap: true,
            label: {
              normal: {
                formatter(v) {
                  let text = v.name;
                  let value_format = v.value + '台';
                  let percent_format = '{' + Math.round(v.percent) + '%}';
                  if (text.length <= 6) {
                    return `${text}\n${value_format}${percent_format}`;
                  } else if (text.length > 6 && text.length <= 12) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6
                    )}\n${value_format}${percent_format}`);
                  } else if (text.length > 12 && text.length <= 18) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12)}\n${value_format}${percent_format}`);
                  } else if (text.length > 18 && text.length <= 24) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18
                    )}\n${value_format}${percent_format}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18,
                      24
                    )}\n${text.slice(24)}\n${value_format}${percent_format}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 6)}\n${text.slice(
                      6,
                      12
                    )}\n${text.slice(12, 18)}\n${text.slice(
                      18,
                      24
                    )}\n${text.slice(24, 30)}\n${text.slice(
                      30
                    )}\n${value_format}${percent_format}`);
                  }
                },
                color: '#fff',
              },
              borderColor: '#aaa',
              color: '#fff',
              borderRadius: 4,
              position: 'top',
            },
            labelLine: {
              show: true,
            },
            data: dataBox,
          },
        ],
      };
      myecharts.setOption(option);
    });
  }
  //获取新闻消息走势
  getSystemMsgCountStatistic() {
    this.dataViewSevervice
      .getSystemMsgCountStatistic({
        startTime: this.startTime,
        endTime: this.endTime,
      })
      .then((res: any) => {
        let dateData = [];
        let numData = [];
        res.content.forEach((val) => {
          dateData.push(val.date.slice(8, 10) + '号');
          numData.push(val.count);
        });
        let myecharts = echarts.init(
          document.getElementById('newsCountCharts')
        );
        const chartOption = {
          grid: {
            x: '10%', //x 偏移量
            y: '16%', // y 偏移量
            width: '87%', // 宽度
            height: '70%', // 高度
          },
          xAxis: {
            type: 'category',
            data: dateData,
            axisLabel: {
              interval: 0,
              // rotate: 40,
              textStyle: {
                color: '#ffffff',
                fontSize: '10',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },
            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
          color: ['#00FFFF'],
          series: [
            {
              data: numData,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top',
                color: '#00FFFF',
              },
            },
          ],
        };
        myecharts.setOption(chartOption);
      });
  }
  //获取活跃租户top10
  getActiveTenantTop10() {
    this.tenantVisible = true;
    this.dataViewSevervice.getActiveTenantTop10().then((res: any) => {
      let dataBox = [];
      let countBox = [];
      res.content.forEach((val, key) => {
        dataBox.unshift(
          (key < 9 && key != 9 ? 'NO.0' + (key + 1) : 'NO.' + (key + 1)) +
            ' ' +
            val.tenantName
        );
        countBox.unshift(val.count);
      });
      let myecharts = echarts.init(
        document.getElementById('tenantTop10Charts')
      );
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> 消息量：{c}',
          backgroundColor: 'rgba(27, 47, 179, .6)',
        },
        grid: {
          width: '90%',
          x: '3%', //x 偏移量
          y: '5%', // y 偏移量
          height: '98%', // 高度
        },
        xAxis: [
          {
            splitLine: {
              show: true,
            },
            type: 'value',
            show: false,
          },
        ],
        yAxis: [
          {
            //名称
            type: 'category',
            position: 'left',
            axisLine: {
              show: false,
            },
            inverse: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: -1,
              color: ['#fff'],
              align: 'left',
              verticalAlign: 'bottom',
              lineHeight: 32,
              fontSize: 14,
              rich: {
                nt1: {
                  color: '#FFDF00',
                  fontSize: 14,
                  align: 'left',
                },
                nt: {
                  color: '#00FFFF',
                  fontSize: 14,
                  align: 'left',
                },
              },
              formatter: function (value, index) {
                if (dataBox.length - 3 < index + 1) {
                  return ['{nt' + 1 + '|' + value + '}'].join('\n');
                } else {
                  return ['{nt|' + value.slice(0, 5) + '}'].join('\n');
                }
              },
            },
            data: dataBox,
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: 6, // 柱子宽度
            label: {
              show: true,
              position: 'right', // 位置
              color: '#1CD8A8',
              fontSize: 14,
            }, // 柱子上方的数值
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 1, 1, [
                { offset: 0, color: '#334EFF' },
                { offset: 1, color: '#00FFFF' },
              ]),
            },
            data: countBox,
          },
        ],
      };
      myecharts.setOption(option);
    });
  }
  //获取活跃设备Top10
  getDeviceTop10() {
    this.deviceVisible = true;
    this.dataViewSevervice.getActiveDeviceTop10().then((res: any) => {
      let dataAxis = [];
      let data = [];
      this.deviceData = res.content;
      res.content.forEach((val) => {
        dataAxis.push(val.deviceName);
        data.push(val.count);
      });
      let myecharts = echarts.init(document.getElementById('deivceCharts'));
      let option = {
        color: ['#3398DB'],
        grid: {
          x: '10%', //x 偏移量
          y: '16%', // y 偏移量
          width: '87%', // 宽度
          height: '70%', // 高度
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> 消息量：{c}',
          backgroundColor: 'rgba(27, 47, 179, .6)',
        },
        xAxis: [
          {
            triggerEvent: true,
            type: 'category',
            data: dataAxis,
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLabel: {
              interval: 0,
              show: true,
              textStyle: {
                color: '#ffffff',
              },
              formatter: function (value) {
                return value.length > 5 ? value.slice(0, 5) + '...' : value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },

            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
        ],
        series: [
          {
            // For shadow
            type: 'bar',
            itemStyle: {
              color: 'rgba(0,0,0,0.05)',
            },
            barGap: '-100%',
            barCategoryGap: '40%',
            animation: false,
          },
          {
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#00FFFF' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#334EFF' },
              ]),
            },
            label: {
              show: true,
              position: 'top',
              color: '#00FFFF',
            },
            barWidth: 10, //柱图宽度
            data: data,
          },
        ],
      };
      myecharts.setOption(option);
      this.extension(myecharts);
      let dataList=this.deviceData
      myecharts.on('click', (event: any) => {
        this.deviceIndex = event.dataIndex;
        // this.getMqttMsgCountStatistic(
        //   this.deviceData[event.dataIndex].deviceId
        // );
        this.getMqttMsgCountStatistic(
          dataList[event.dataIndex].deviceId
        );
      });
    });
  }
  //获取告警设备Top10
  getAlarmDeviceTop10() {
    this.alarmVisible = true;
    this.dataViewSevervice.getAlarmDeviceTop10().then((res: any) => {
      let dataAxis = [];
      let data = [];
      this.deviceData = res.content;
      res.content.forEach((val) => {
        dataAxis.push(val.deviceName);
        data.push(val.count);
      });
      let myecharts = echarts.init(
        document.getElementById('AlarmdeivceCharts')
      );
      let option = {
        color: ['#3398DB'],
        grid: {
          x: '10%', //x 偏移量
          y: '16%', // y 偏移量
          width: '87%', // 宽度
          height: '70%', // 高度
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> 告警量：{c}',
          backgroundColor: 'rgba(27, 47, 179, .6)',
        },
        xAxis: [
          {
            triggerEvent: true,
            type: 'category',
            data: dataAxis,
            axisTick: {
              alignWithLabel: true,
              show: false,
            },

            axisLabel: {
              interval: 0,
              show: true,
              textStyle: {
                color: '#ffffff',
              },
              formatter: function (value) {
                return value.length > 3 ? value.slice(0, 3) + '...' : value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },

            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
        ],
        series: [
          {
            // For shadow
            type: 'bar',
            itemStyle: {
              color: 'rgba(0,0,0,0.05)',
            },
            barGap: '-100%',
            barCategoryGap: '40%',
            animation: false,
          },
          {
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FFA77A' },
                { offset: 1, color: '#FF6669' },
              ]),
            },
            label: {
              show: true,
              position: 'top',
              color: '#00FFFF',
            },
            barWidth: 10, //柱图宽度
            data: data,
          },
        ],
      };
      myecharts.setOption(option);
      this.extension(myecharts);
    });
  }
  //获取设备数量走势
  getDeviceCount() {
    this.countVisible = true;
    this.dataViewSevervice
      .getDeviceCountStatistic({
        startTime: this.startTime,
        endTime: this.endTime,
      })
      .then((res: any) => {
        if(res.code==200){
          let dateData = [];
          let numData = [];
          res.content.forEach((val) => {
            dateData.push(val.date.slice(8, 10) + '号');
            numData.push(val.count);
          });

          let myecharts = echarts.init(
            document.getElementById('deivceCountCharts')
          );
          const chartOption = {
            grid: {
              x: '8%', //x 偏移量
              y: '16%', // y 偏移量
              width: '87%', // 宽度
              height: '70%', // 高度
            },
            xAxis: {
              type: 'category',
              data: dateData,
              axisLabel: {
                interval: 0,
                // rotate: 40,
                textStyle: {
                  color: '#ffffff',
                  fontSize: '10',
                },
              },
              axisLine: {
                lineStyle: {
                  color: '#ffffff',
                  opacity: 0.1,
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#ffffff',
                },
              },
              splitLine: {
                show: true,
                lineStyle: { color: 'rgba(255,255,255,.1)' },
              },
            },
            color: ['#C2FF33'],
            series: [
              {
                data: numData,
                type: 'line',
                smooth: true,
                label: {
                  show: true,
                  position: 'top',
                  color: '#C2FF33',
                },
              },
            ],
          };
          myecharts.setOption(chartOption);
        }else{
          this.messages="正在努力加载中..."
        }
      });
  }
  //租户数量/客户数量统计
  getTenantCountStatistic() {
    this.tenantVisible = false;
    this.dataViewSevervice
      .getTenantCountStatistic({
        startTime: this.startTime,
        endTime: this.endTime,
      })
      .then((res: any) => {
        let dateData = [];
        let numData = [];
        res.content.forEach((val) => {
          dateData.push(val.date.slice(8, 10) + '号');
          numData.push(val.count);
        });
        let myecharts = echarts.init(
          document.getElementById('tenantCountCharts')
        );
        const chartOption = {
          grid: {
            x: '10%', //x 偏移量
            y: '12%', // y 偏移量
            width: '80%', // 宽度
            height: '70%', // 高度
          },
          xAxis: {
            type: 'category',
            data: dateData,
            axisLabel: {
              interval: 0,
              // rotate: 40,
              textStyle: {
                color: '#ffffff',
                fontSize: '10',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },
            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
          color: ['#00FFFF'],
          series: [
            {
              data: numData,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top',
                color: '#00FFFF',
              },
            },
          ],
        };
        myecharts.setOption(chartOption);
      });
  }
  // 告警数量统计
  getAlarmCountStatistic() {
    this.alarmVisible = false;
    let oldNum=2
    this.newNum++
    console.log(this.newNum)
    if(oldNum==this.newNum){
      console.log("第一次执行");
    }
    this.dataViewSevervice
      .getAlarmCountStatistic({
        startTime: this.startTime,
        endTime: this.endTime,
      })
      .then((res: any) => {
        let dateData = [];
        let numData = [];
        res.content.forEach((val) => {
          dateData.push(val.date.slice(8, 10) + '号');
          numData.push(val.count);
        });
        let myecharts = echarts.init(
          document.getElementById('AlarmCountCharts')
        );
        const chartOption = {
          grid: {
            x: '10%', //x 偏移量
            y: '16%', // y 偏移量
            width: '87%', // 宽度
            height: '70%', // 高度
          },
          xAxis: {
            type: 'category',
            data: dateData,
            axisLabel: {
              interval: 0,
              // rotate: 40,
              textStyle: {
                color: '#ffffff',
                fontSize: '10',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },
            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
          color: ['#FF9966'],
          series: [
            {
              data: numData,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top',
                color: '#FF9966',
              },
            },
          ],
        };
        myecharts.setOption(chartOption);
      });
  }
  //产品数量告警统计
  getProductCountStatistic() {
    this.countVisible = false;
    this.dataViewSevervice
      .getProductCountStatistic({
        startTime: this.startTime - 24 * 60 * 60 * 1000 * 30 * 9,
        endTime: this.endTime,
      })
      .then((res: any) => {
        let dateData = [];
        let numData = [];
        res.content.forEach((val) => {
          dateData.push(val.date.slice(5, 7) + '月');
          numData.push(val.count);
        });
        let myecharts = echarts.init(
          document.getElementById('productCountCharts')
        );
        const chartOption = {
          grid: {
            x: '10%', //x 偏移量
            y: '16%', // y 偏移量
            width: '87%', // 宽度
            height: '70%', // 高度
          },
          xAxis: {
            type: 'category',
            data: dateData,
            axisLabel: {
              interval: 0,
              // rotate: 40,
              textStyle: {
                color: '#ffffff',
                fontSize: '10',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },
            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
          color: ['#C2FF33'],
          series: [
            {
              data: numData,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top',
                color: '#C2FF33',
              },
            },
          ],
        };
        myecharts.setOption(chartOption);
      });
  }
  // 单一设备的消息量统计
  getMqttMsgCountStatistic(id) {
    this.deviceVisible = false;
    this.dataViewSevervice
      .getMqttMsgCountStatistic({
        startTime: this.startTime,
        endTime: this.endTime,
        deviceId: id,
      })
      .then((res: any) => {
        let dateData = [];
        let numData = [];
        res.content.forEach((val) => {
          dateData.push(val.date.slice(8, 10) + '号');
          numData.push(val.count);
        });
        let myecharts = echarts.init(
          document.getElementById('onlyDeivceCharts')
        );
        const chartOption = {
          grid: {
            x: '10%', //x 偏移量
            y: '11%', // y 偏移量
            width: '87%', // 宽度
            height: '65%', // 高度
          },
          xAxis: {
            type: 'category',
            data: dateData,
            axisLabel: {
              interval: 0,
              // rotate: 40,
              textStyle: {
                color: '#ffffff',
                fontSize: '10',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                opacity: 0.1,
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
              },
            },
            splitLine: {
              show: true,
              lineStyle: { color: 'rgba(255,255,255,.1)' },
            },
          },
          color: ['#00FFFF'],
          series: [
            {
              data: numData,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top',
                color: '#00FFFF',
              },
            },
          ],
        };
        myecharts.setOption(chartOption);
      });
  }

  closeView() {
    //关闭当前页
    history.go(-1);
  }

  extension(mychart) {
    //判断是否创建过div框,如果创建过就不再创建了
    var id = document.getElementById('extension');
    if (!id) {
      var div = '<div id = \'extension\' sytle="display:none"></div>';
      $('html').append(div);
    }
    mychart.on('mouseover', function (params) {
      if (params.componentType == 'xAxis') {
        $('#extension')
          .css({
            position: 'absolute',
            color: '#fff',
            background: '#1A2EB0',
            'font-size': '12px',
            padding: '5px',
            display: 'inline',
            border: '1px solid #333',
            'z-index': '100',
          })
          .text(params.value);
        $('html').mousemove(function (event) {
          var xx = event.pageX - 30;
          var yy = event.pageY + 20;
          $('#extension').css('top', yy).css('left', xx);
        });
      }
    });
    mychart.on('mouseout', function (params) {
      if (params.componentType == 'xAxis') {
        $('#extension').css('display', 'none');
      }
    });
  }
}
