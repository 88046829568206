import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-serve',
  templateUrl: './error-serve.component.html',
  styleUrls: ['./error-serve.component.scss'],
})
export class ErrorServeComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}
  goBack() {
    this.route.navigate(['']);
  }
}
