import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup, FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[input-noSpace]',
})
export class InputNoSpaceDirective {
  constructor(private elementRef: ElementRef, private control: NgControl) {}

  @HostListener('keydown', ['$event', '$event.target'])
  keydownFun(evt, target) {
    if (evt.key.trim() == '') {
      evt.preventDefault();
    }
  }

  @HostListener('keyup', ['$event', '$event.target'])
  keyupFun(evt, target) {
    if (target.value) {
      this.control.control.setValue(target.value.replace(/(\s*)/g, ''));
      this.control.control.setValue(target.value.replace(/(%|\\)/g, ''));
    }
  }

  @HostListener('blur', ['$event', '$event.target'])
  blurFun(evt, target) {
    if (target.value) {
      this.control.control.setValue( target.value.trim());
    }
  }
}
