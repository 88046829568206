import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { url, OpenUrl } from '../utils/config.url';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}
  //获取消息列表
  public getNewsList(pageLink, obj) {
    return this.http
      .post(
        `${url}/systemMsg/getPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  //创建消息
  public saveNews(obj) {
    return this.http.post(`${url}/systemMsg/save`, obj).toPromise();
  }
  //删除消息
  public removeNews(id) {
    return this.http.delete(`${url}/systemMsg/remove/${id}`).toPromise();
  }
  //推送消息
  public publishNews(obj) {
    return this.http.post(`${url}/systemPushRecord/push`, obj).toPromise();
  }
  //获取消息推送记录
  public getNewsRecord(pageLink, obj) {
    return this.http
      .post(
        `${url}/systemPushRecord/getPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`,
        obj
      )
      .toPromise();
  }
  //取消消息推送
  public cancelNews(id) {
    return this.http
      .delete(`${url}/systemPushRecord/cancelPush/${id}`)
      .toPromise();
  }
  //租户-查询推送记录分页
  public getTenantNews(pageLink) {
    return this.http
      .get(
        `${OpenUrl}auth/api/v1/tenant/systemMsg/getPage?pageNo=${pageLink.pageNo}&pageSize=${pageLink.pageSize}`
      )
      .toPromise();
  }
  //获取消息详情
  public getNewsDetail(id) {
    return this.http.get(`${url}/systemMsg/getDetail/${id}`).toPromise();
  }
  //批量已读
  public newsRead(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/tenant/systemMsg/batchReaded`, obj)
      .toPromise();
  }
  //批量删除
  public newsRemove(obj) {
    return this.http
      .post(`${OpenUrl}auth/api/v1/tenant/systemMsg/batchRemove`, obj)
      .toPromise();
  }
}
