import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class CommunicateService {
  private renderSubejct: Subject<any> = new Subject<any>();
  constructor() {}
  public getRenderSubject() {
    return this.renderSubejct;
  }

  public emitRenderInfo(msg: any): void {
    if (msg) {
      this.renderSubejct.next(msg);
    }
  }
}
