import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { HomeComponent } from './components/common/home/home.component';
import { LoginComponent } from './components/common/login/login.component';
import { ErrorComponent } from './components/error/error/error.component';
import { ErrorServeComponent } from './components/error/error-serve/error-serve.component';
import { LoginResetComponent } from './components/common/login-reset/login-reset.component';
// import { DeleteDlogComponent } from './components/common/delete-dlog/delete-dlog.component';
import { DataViewComponent } from './components/common/data-view/data-view.component';
import { PreloadAllModules } from '@angular/router';
import { RouteStrategyService } from "./route-strategy.service";
//路由守卫
import { AuthGuard } from './shared/utils/route.guard';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { animation: 'HomePage' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'flight-booking',
        loadChildren: () =>
          import('./components/flight-booking/flight-booking.module').then(
            (mod) => mod.FlightBookingModule
          ),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./components/resources/resources.module').then(
            (mod) => mod.ResourcesModule
          ),
      },
      {
        path: 'person',
        loadChildren: () =>
          import('./components/system/system.module').then(
            (mod) => mod.SystemModule
          ),
      },
      {
        path: 'role',
        loadChildren: () =>
          import('./components/role/role.module').then((mod) => mod.RoleModule),
      },
      {
        path: 'alarm',
        loadChildren: () =>
          import('./components/alarm/alarm.module').then(
            (mod) => mod.AlarmModule
          ),
      },
      {
        path: 'group',
        loadChildren: () =>
          import('./components/group/group.module').then(
            (mod) => mod.GroupModule
          ),
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./components/organization/organization.module').then(
            (mod) => mod.OrganizationModule
          ),
      },
      {
        path: 'apk',
        loadChildren: () =>
          import('./components/apk/apk.module').then((mod) => mod.ApkModule),
      },
      {
        path: 'config',
        loadChildren: () =>
          import('./components/config/config.module').then(
            (mod) => mod.ConfigModule
          ),
      },
      {
        path: 'ota',
        loadChildren: () =>
          import('./components/ota/ota.module').then((mod) => mod.OtaModule),
      },
      {
        path: 'product',
        loadChildren: () =>
          import('./components/product/product.module').then(
            (mod) => mod.ProductModule
          ),
      },
      {
        path: 'mcu',
        loadChildren: () =>
          import('./components/mcu/mcu.module').then((mod) => mod.McuModule),
      },
      {
        path: 'device',
        loadChildren: () =>
          import('./components/device/device.module').then(
            (mod) => mod.DeviceModule
          ),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'LoginPage' },
  },
  {
    path: 'login-reset',
    component: LoginResetComponent,
    data: { animation: 'LoginPage' },
  },
  {
    path: 'data-view',
    component: DataViewComponent,
    data: { animation: 'LoginPage' },
  },
  {
    path: '500',
    component: ErrorComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: '**',
    component: ErrorServeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: RouteStrategyService }
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
